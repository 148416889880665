import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  page: {
    pageTitle: "Peachfinance",
  },
  metadata: {
    title: "%s | Peachfinance Developer",
  },
  theme: {
    light: {
      primary: "22.1 89.7% 54.3%",
    },
    dark: {
      background: "0 0% 6.67%",
      primary: "22.1 89.7% 54.3%",
      primaryForeground: "360 100% 100%",
      mutedForeground: "215 9.15% 66.66%",
    },
  },
  navigation: [
    {
      label: "API Reference",
      path: "/api",
    },
  ],
  redirects: [{ from: "/", to: "/api" }],
  apis: {
    type: "url",
    input: "https://blue-sloth-main-a8e5667.d2.zuplo.dev/schemas/peach",
    path: "/api",
  },
  docs: {
    files: "/pages/**/*.mdx",
  },
};

export default config;
